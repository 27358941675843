import { useField } from '@rvf/react-router'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import {
	Select as ShadcnSelect,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectGroup,
	SelectItem,
} from '#app/components/ui/select'
import { capitalize } from '#app/utils'
import { Label } from '../ui/label'
import { ErrorMessage } from './error-message'
import { inputClassname, labelClassname } from './form-classes'
import { type RVFInputFieldProps } from './rvf-input'

export const RVFSelect = React.forwardRef<
	React.ElementRef<typeof ShadcnSelect>,
	RVFInputFieldProps & {
		options: { value: string; display?: string; icon?: JSX.Element }[]
	}
>(({ label, name, form, className, options, placeholder, ...props }, _ref) => {
	const { error, getInputProps } = useField(form.scope(name), {
		validationBehavior: {
			initial: 'onBlur',
			whenTouched: 'onBlur',
			whenSubmitted: 'onSubmit',
		},
	})
	// For whatever reason, getInputProps doesn't like expecting name :eyeroll:
	const { ref: inputRef, onChange, value, ...rest } = getInputProps()
	return (
		<div className={className} ref={inputRef}>
			<Label htmlFor={name} className={labelClassname}>
				{label ?? ' '}
			</Label>
			<ShadcnSelect
				aria-label="Select a quantity"
				{...rest}
				value={value as string}
				// Because Shadcn renamed this piece
				onValueChange={onChange}
			>
				<SelectTrigger
					className={twMerge(inputClassname, props.inputClassname)}
				>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						{options.map((o) => (
							<SelectItem key={o.value} value={o.value}>
								<div className="flex cursor-pointer items-center gap-x-2">
									{o.icon}
									{capitalize(o.display || o.value)}
								</div>
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</ShadcnSelect>
			<ErrorMessage error={error() as string} />
		</div>
	)
})
